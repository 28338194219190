import React, {useState} from 'react';
import styled from 'styled-components';
import ContentWrapper from '../../Components/Layout/ContentWrapper';
import StaticImage from '../../Components/StaticImage';
import {Heading, Button, Text, Row, ToggleDrawer} from '../../Widgets';
import {Link} from 'gatsby';
import Item from '../../Components/BlogItem';
import {Row as AntRow, Col, Drawer} from 'antd';
import useBlogList from '../../hooks/use-blog-list';
import {normalizePath} from '../../Utils/pathUtil';
const appConfig = require('../../data.json');

const LabelHeader = styled.div`
  border-bottom: solid 1px ${appConfig.colors.border};
  padding: 10px 0;
  color: #515151;
  font-weight: 600;
  text-align: center;
`;

function Label({selected, label, idx, onCloseDrawer}) {
  return (
    <div
      style={{
        borderBottom: 'solid 1px ' + appConfig.colors.border,
        padding: '10px 0',
        textAlign: 'center',
        fontSize: 18,
        letterSpacing: 4,
      }}>
      <Link
        to={idx === 0 ? `/articles` : `/articles/${label}`}
        onClick={onCloseDrawer}
        style={{
          color: selected ? appConfig.colors.second : '#989898',
        }}>
        {label}
      </Link>
    </div>
  );
}

function Hashtag({selected, hashtag, onCloseDrawer}) {
  return (
    <div
      style={{
        backgroundColor: selected ? appConfig.colors.second : 'transparent',
        border: selected ? 0 : 'solid 1px ' + appConfig.colors.border,
        padding: '5px 10px',
        margin: '0 8px 8px 0',
      }}>
      <Link
        to={`/articles/?hashtag=${hashtag}`}
        onClick={onCloseDrawer}
        style={{
          color: selected ? 'white' : '#989898',
        }}>
        {hashtag}
      </Link>
    </div>
  );
}

function SideSection({labels, label, onCloseDrawer = () => {}}) {
  return (
    <React.Fragment>
      <LabelHeader>文章分類 CATEGORY</LabelHeader>
      {['全部文章', ...labels].map((_label, idx) => {
        const selected = (label && label === _label) || (!label && idx === 0);
        return (
          <Label
            label={_label}
            selected={selected}
            idx={idx}
            key={idx}
            onCloseDrawer={onCloseDrawer}
          />
        );
      })}

      <div style={{marginTop: 45}} />

      {appConfig.blogSocialMediaDisplay &&
        [
          {
            image: 'home-social-fb.png',
            link: appConfig.socialMedia.facebook.link,
          },
          {
            image: 'home-social-line.png',
            link: appConfig.socialMedia.line.link,
          },
          {
            image: 'home-social-ig.png',
            link: appConfig.socialMedia.instagram.link,
          },
        ].map((icon, idx) => (
          <div style={{marginBottom: 21}}>
            <a
              className={'social-icon'}
              key={idx}
              href={`${icon.link}`}
              target="_blank"
              rel="noreferrer">
              <StaticImage
                filename={`${icon.image}`}
                extraStyle={{height: 120}}
              />
            </a>
          </div>
        ))}

      <div style={{marginTop: 50}} />

      {/* <LabelHeader style={{ marginBottom: 10 }}>
              標籤 TAGS
            </LabelHeader>
            <div style={{
              display: "flex",
              flexWrap: "wrap"
            }}>
            {['hashtag1', 'hashtag2', 'hashtag3', 'hashtag4', 'hashtag5'].map((tag, idx) => {
              const selected =
                (hashtag && hashtag === tag) || (!hashtag && idx === 0);
              return (
                <Hashtag hashtag={tag} selected={selected} idx={idx} key={idx} onCloseDrawer={onCloseDrawer}/>
              );
            })}
            </div> */}
    </React.Fragment>
  );
}

export default function BlogList(props) {
  const {label, hashtag} = props.pageContext;
  const [showDrawer, setShowDrawer] = useState(false);

  const {records, labels, bottomItemOrientation} = useBlogList({label});

  return (
    <>
      <div
        style={{
          paddingTop:
            50 +
            (appConfig.navbarHeightExcludePages.includes(
              normalizePath(props.location.pathname),
            )
              ? appConfig.navbarHeight
              : 0),
          paddingBottom: 30,
          backgroundColor: appConfig.topSectionBackgroundColor,
        }}>
        <h2
          style={{
            textAlign: 'center',
            color: appConfig.colors.textSecond,
          }}>
          {appConfig.mapping.articles}
        </h2>
      </div>
      <ContentWrapper>
        <AntRow>
          <Col xs={0} sm={6}>
            <SideSection label={label} labels={labels} />
          </Col>

          <Col xs={0} sm={1}></Col>

          <Col xs={24} sm={17}>
            <h2
              style={{
                color: appConfig.colors.second,
                marginBottom: 30,
                paddingBottom: 20,
                borderBottom: 'solid 1px ' + appConfig.colors.border,
              }}>
              {label || '全部文章'}
            </h2>
            <div style={{marginBottom: 70}}>
              {records.map((record) => (
                <Item
                  key={record.id}
                  record={record}
                  extraStyle={{marginBottom: 10}}
                  type={bottomItemOrientation}
                />
              ))}
            </div>
          </Col>
        </AntRow>

        <ToggleDrawer onClick={() => setShowDrawer(true)}>
          <span
            style={{color: '#ffffff', whiteSpace: 'pre'}}>{`文章\n列表`}</span>
        </ToggleDrawer>

        <Drawer
          placement="left"
          closable={false}
          onClose={() => setShowDrawer(false)}
          visible={showDrawer}>
          <SideSection
            label={label}
            labels={labels}
            onCloseDrawer={() => setShowDrawer(false)}
          />
        </Drawer>
      </ContentWrapper>
    </>
  );
}
