import React from 'react';
import styled from 'styled-components';
import {Link} from 'gatsby';
import {Button} from '../../Widgets';
import {dateUtil} from '../../Utils';
import Image from '../../Components/Image';
const config = require('../../data.json');

const PORTRAIT_RADIO = 271 / 440;
const LANDSCAPE_RADIO = 219 / 360;

export default function Item({record, type = 'portrait', extraStyle = {}}) {
  const Wrapper = type === 'portrait' ? WrapperPortrait : WrapperLandscape;

  return (
    <Link style={extraStyle} to={`/article?id=${record.id}`}>
      <Wrapper>
        {/* <div style={type === 'landscape' ? { flexBasis: 393, flexGrow: 0} : {}}> */}
        <div style={type === 'landscape' ? {flexBasis: 360, flexGrow: 0} : {}}>
          <Image
            src={record.image}
            alt={'article ' + record.id}
            style={{objectFit: 'cover'}}
            {...(type === 'portrait'
              ? {
                  ratio: PORTRAIT_RADIO,
                }
              : {
                  // ratio: LANDSCAPE_RADIO,
                })}
            background={type === 'landscape' ? true : false}
          />
        </div>

        <section>
          <div className="label">
            {(record.label && record.label[0]) || '-'}{' '}
            <span style={{marginLeft: 8}}>
              {dateUtil.format(
                dateUtil.processTimestamp(record.created),
                'YYYY-MM-DD',
              )}
            </span>
          </div>
          <h3>{record.title || 'No title'}</h3>
          <div className="abstract">{record.outline || 'No abstract'}</div>
          <div style={{alignSelf: 'flex-end', color: config.colors.second}}>
            Read More {'>'}
          </div>
        </section>
      </Wrapper>
    </Link>
  );
}

const WrapperPortrait = styled.div`
  min-height: 340px;
  display: flex;
  flex-direction: column;
  border: solid 1px #eee;
  box-shadow: 4px 5px 6px -1px #e0e0e0;

  & > section {
    flex: 1 0 50%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    color: ${config.colors.text};
    font-size: ${config.fontSize.sm}px;

    & > .label {
      margin-bottom: 10px;
    }

    & > h3 {
      height: 54px;
      padding: 0;
      margin: 0;
      margin-bottom: 15px;
      color: ${config.colors.second};
      font-size: ${config.fontSize.lg}px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    & > .abstract {
      height: 70px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      margin-bottom: 10px;
      line-height: 1.8;
    }
  }

  @media only screen and (max-width: ${config.breakpoints.sm}px) {
    margin: 0 0 10px 0;
  }
`;

const WrapperLandscape = styled.div`
  display: flex;
  border: solid 1px #eee;
  box-shadow: 4px 5px 6px -1px #e0e0e0;
  margin-bottom: 30px;

  & > section {
    margin: 20px;
    display: flex;
    position: relative;
    flex-direction: column;
    flex: 1;
    color: ${config.colors.text};
    font-size: ${config.fontSize.sm}px;

    & > h3 {
      padding: 0;
      margin: 10px 0;
      color: ${config.colors.second};
      font-size: ${config.fontSize.lg}px;
      flex: 0 0 52px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    & > .abstract {
      flex: 1 0 80px;
      margin: 0;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      line-height: 1.8;
      overflow: hidden;
    }
  }

  @media only screen and (max-width: ${config.breakpoints.sm}px) {
    & > section {
      & > h3 {
        margin: 0;
        flex: 1;
      }
      & > p {
        display: none;
      }
    }
  }
`;
